* {
    font-family: 'Montserrat', sans-serif;
}

.container-1{
    left: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.item-container {
    position: relative;
    margin: 24px;
    width: 320px;
    height: 570px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.img-container,
.body-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.img-container img {
    width: 100%;
    height: 450px;
}

.body-container {
    position: relative;
}

.overlay {
    position: relative;
    width: 100%;
    height: 400px;
    background-color: rgba(24, 83, 122, 0.6);
    opacity: 0;
    transition: height linear 0.4s, opacity linear 0.2s;
}

.item-container:hover .overlay {
    opacity: 1;
    height: 150px;
}

.event-info {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.title,
.price {
    color: rgb(0, 0, 0);
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 12px;
}

.info {
    letter-spacing: 0.5px;
    margin-bottom: 6px;
}

.separator {
    width: 20%;
    height: 6px;
    background-color: rgb(120, 1, 22);
    margin-bottom: 16px;
}

.additional-info {
    border-top: 1px solid #bbb;
    margin-top: 12px;
    padding: 28px;
    padding-bottom: 0;
}

.additional-info .info {
    font-size: 0.9em;
    margin-bottom: 20px;
    text-align: center;
}

.info i {
    color: #18537a;
    font-size: 1.1em;
    margin-right: 4px;
}

.info span {
    color: #18537a;
    font-weight: bolder;
}

.action {
    color: #fff;
    border: 3px solid #fff;
    background-color: transparent;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    outline: none;
    cursor: pointer;
    padding: 12px;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 2px;
    transition: background-color 0.4s, top 0.4s;
}

.item-container:hover .action {
    top: 50px;
}

.action:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.header-text-Events{
    font-weight: normal;
    font-size: 30px;
    text-align: center;
    color: black;
}

@media screen and  (max-width: 768px) {  /* Adjust this breakpoint as per your needs */
    .container {
        justify-content: center;
        max-width:200px;
        flex-direction: column;
        height: auto;
    }
}