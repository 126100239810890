.section-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-text {
    padding-bottom: 45rem;
    font-weight: normal;
    font-size: 70px;
    text-align: center;
    color: black;
  }
  .image-slider {
    max-width: 100%;
    width: 1200px;
    object-fit: cover;
    height: 500px;
    margin: 0 auto;
    position: absolute;
    top:210px;
}

.inner-text{
    color: rgb(220,47,2);
    font-size: 70px;
    font-weight: bolder;
}

.slider-container {
    max-width: 1600px;
    width: 100%;
    height: 400px;
    margin: 0 auto;
}

.mission-section {
    padding: 10px;
    text-align: center;
    color: black;
}
.mission-section h1{
    padding: 10px;
    font-size: 40px;
    font-weight: bold;
}
.mission-statement {
    padding: 10px;
    font-size: 25px;
    color: #333;
    margin-bottom: 20px;
}

.under-line{
    border: 2px solid rgb(255, 195, 0);
    border-radius: 2px;
}

@media (max-width: 810px) {
  .header-text{
    padding-bottom: 45rem;
    font-weight: normal;
    font-size: 20px;
    text-align: center;
  }
  .inner-text{
    color: rgb(220,47,2);
    font-size: 30px;
    font-weight: bolder;
}

  .image-slider {
    max-width: 1200px;
    width: 100%;
    height: 275px;
    margin: 0 auto;
    position: absolute;
}
.mission-section {
    padding: 20px;
    text-align: center;
    color: black;
    font-size: 10px;
}
.mission-statement {
    padding: 20px;
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
}
}