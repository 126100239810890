.footer-icons{
  display: flex;
  flex-direction: row;
  width: 100%;
}


.footer-icons i {
    padding: 0.5rem 0.6rem;
    background: #e1e1e1;
    color: #f60838;
    margin: 0 0.5rem;
    border-radius: 50%;
  }
  
  .footer-icons i:hover {
    background: #f60838;
    color: #fff;
    transition: 0.6s;
    cursor: pointer;
  }

  .footer-section-padding{
    padding: 2rem 6rem;
  }

  .footer{
   
  }
  .sb_footer {
    display: flex;
    flex-direction: column;
  }
  .footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
  }
  .footer-links-div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
  }
  .footer-links-div h4{
    font-size: 18px;
    line-height: 17px;
    margin-bottom: .9rem;
  }
  .footer-links-div p{
    font-size: 15px;
    line-height: 15px;
    margin-bottom: .5rem 0;
    cursor: pointer;
  }
  .footer-below{
    display: flex;
    flex-direction: column;
    line-height: 15px;
    margin: .5rem;
    margin-top: .2rem;
    cursor: default;
  }
  .footer-below p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: black;
    font-weight: 600;
  }
  hr{
    color: black !important;
    width: 100%;
  }