.body{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222;
    min-height: 100vh;


}
.container{
    position: relative;
    width:1000px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px;
}
.container .card{
    position: relative;
    height: 400px;
    background: #fff;
    display: flex;
    width: 100%;
    margin: 30px 0;
}

.img-size{
    width: 200px;
    height: 200px;
}
.container .card .imgBx{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #333;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: .5s ease-in-out;
}
.container .card:hover .imgBx{
    width: 300px;
    height: 250px;
    left: -75px;
    top: calc(45% - 75px);
    transition: 0.5s ease-in-out;
    background: #ff0057;
}

.container .card .imgBx::before{
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 6em;
    color: rgba(255,255,255,.05);
    font-weight: 700;
}

.container .card .imgBx img{
    transition: .5s ease-in-out;
}

.container .card:hover .imgBx img{
    max-width: 200px;

}

.container .card .content {
    position: absolute;
    right: 0;
    width: calc(100% - 225px);
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container .card .content h3{
    margin-bottom: 5px;
    font-size: 25px;
    color: green;
}
.container .card .content p{
    font-size: 15px;
}


@media (max-width: 992){
    
    .container{
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .container .card{
        width:400px;
    }
}

@media (max-width:770px)
{
        .container .card{
            max-width:300px;
            width: fit-content;
            flex-direction: column;
            height: auto;
        }
        .container .card .imgBx{
            position: relative;
        }

        
        .container .card .imgBx,
        .container .card:hover .imgBx
        {
            width: 100%;
            height: 200px;
            left: 0;
        }
        .container .card .imgBx img,
        .container .card:hover .imgBx img{
            max-width: 100px;
        }
        .container .card .content{
            position: relative;
            width: 100%;
        }
        .container .card .content h3{
            margin-bottom: 5px;
            font-size: 20px;
            color: green;
        }
        .container .card .content p{
            font-size: 12px;
        }
    
}