@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
  }


  .Container{
    background-color: rgb(253, 249, 249);
  }

  #root{
    width: 100%;
  }