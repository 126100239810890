*,
*::before,
*::after{
    box-sizing: border-box;
}



.img-slider-img{
    object-fit: fill;
    width: 100%;
    height: 100%;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    transition: translate 300ms ease-in-out;
}

.img-slider-btn{
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 100 ms ease-in-out;
}

.img-slider-btn:hover{
    background-color: rgb(0,0,0,0.3);
}
.img-slider-btn:hover > * {
    animation: squish 200ms ease-in-out
}

.img-slider-btn > *{
    stroke: white;
    fill:black;
    width: 2rem;
    height: 2rem;
}

@keyframes squish {
    50% {
        scale: 1.40.6;
    }
}

.img-slider-dot-btn {
    all: unset;
    display: block;
    cursor: pointer;
    width: 1rem;
    height: 2rem;
    transition: scale 100ms ease-in-out;
  }
  
  .img-slider-dot-btn:hover,
  .img-slider-dot-btn:focus-visible {
    scale: 1.2;
  }
  
  .img-slider-dot-btn > * {
    stroke: white;
    fill: black;
    height: 100%;
    width: 100%;
  }
  
  .img-slider-dot-btn:focus-visible,
  .img-slider-btn:focus-visible {
    outline: auto;
  }
  